<template>
    <div class="about container">
    <h1>Recipes</h1>
    <div class="recipe-row">
      <div  @click="() => onSelect(data)" class="current-recipe" v-for="data in myJson.servicesJson" :key="data.id">

        <div class="current-recipe__image-container">
  <div class="current-recipe__image">
    <img :alt="data.name+' Logo'" :src="data.image.small">
  </div>
  </div>
       
       <div class="current-recipe__text-container current-recipe__rating-container">
 
  <p class="current-recipe__title">{{data.name}}</p>
  <p class="current-recipe__subtitle" title="with Chilli, Ginger and Lime">{{data.subtitle}}</p>
</div>

         </div>
     </div>
     <h1>Chosen Recipes</h1>
     <div class="recipe-row">
        <div class="recipe" v-for="(item, index) in clickedItems.slice(0, 5)" :key="index">
                  <div class="ingredient-image-container">
 <img class="image-circle" width="100" height="100" :alt="index+' Logo'" :src="item.image.thumbnail">
          <button class="deleteBtn" @click="deleteItem(index)">X</button>
          </div>

{{item.name}}

       </div>
     </div>

     <a :href="'mailto:rob.korbosky@gmail.com?subject=Dinner Order&body='+selected">Email me</a>

      <h1>Shopping List</h1>
 
<div>Number of Ingredients {{filtered.length}}</div>
   <div class="recipe-row">
      
   
        <div class="recipe" v-for="(item, index) in filtered" :key="index">
          <div class="ingredient-image-container">
 <img class="image-circle" width="100" height="100" :alt="index+' Logo'" :src="item.image.medium">
          <button class="deleteBtn" @click="deleteIngredient(index)">X</button>
          </div>
         
<span class="ingredient-single">{{item.name}}</span><span class="ingredient-count">({{item.count}})</span> 

       </div>
     </div>

  </div>
</template>

<script>
import json from '@/store/store.json'
export default{
          data(){
              return{
                  myJson: json,
                  clickedItems: [],
                  limitItems:[],
                  ingredients:[],
                  names:[],
                  filteredIngredients:[],
                  result:[],
                  filtered:[],
                  selected:''
              }
          },
             computed: {
    
  },
   methods: {
    onSelect(data) {
      this.clickedItems.push(data)
      this.ingredients.push(data.ingredients)
      this.names.push(data.name)
      this.pI();
    },

pI(){
  this.selected =  this.names.toString();
console.log(this.clickedItems[0].id)
 this.filteredIngredients = this.ingredients.flat();
// console.log( this.filteredIngredients)
const countDict = this.filteredIngredients.reduce((acc, curr) => {
  const { name } = curr;
  if (acc[name]) ++acc[name];
  else acc[name] = 1;
  return acc;
}, {});

const result = this.filteredIngredients.map((obj) => {
  obj["count"] = countDict[obj.name];
  return obj;
});


 result.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
this.result = result;

console.log(this.result.flat())

const ids = this.result.map(o => o.name)
const filtered = this.result.filter(({name}, index) => !ids.includes(name, index + 1))


console.log(filtered)
this.filtered =  filtered
},
deleteIngredient(index) {
this.filtered.splice(index, 1);
},
deleteItem(index) {
  this.clickedItems.splice(index, 1);
  this.ingredients.splice(index, 1);
 
  this.names.splice(index, 1);
  // this.result = ''
  // // console.log(this.filteredIngredients)
  this.pI();
},



  },
          created() {
             console.log(this.myJson.servicesJson);

    //       this.myJson.servicesJson.forEach(service => {
    // //  console.log(service.id)
    //  })
     
        },
      }
</script>






<style>

.ingredient-image-container{
  position:relative;
}
.deleteBtn{
  position:absolute;
  z-index:1;
  left:0;
}

.ingredient-count{
  font-style: italic;
  color: #716d6a;
}

.image-circle{
      border-radius: 50%;
}
.container{
  max-width:80%;
  margin:auto;
}
.recipe-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.recipe{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:start;
  width:250px;
  max-width: 10rem;
  margin-bottom: 20px;
}

.current-recipe {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.11);
    width: 300px;
    margin-bottom:1rem;
}

.current-recipe__image-container {
    position: relative;
    cursor: pointer;
    height: 170px;
    overflow: hidden;
}

.current-recipe__image {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 170px;
    width: 100%;
}

.current-recipe__subtitle {
    color: #716d6a;
}

.current-recipe__title {
    font-weight: bold;
} 
.current-recipe__subtitle, .current-recipe__title {
    line-height: 1.5;
    cursor: pointer;
    margin: 0;
}
.current-recipe__text-container {
    height: 120px;
}
</style>
